
// --== Tema de cores
class KorpayBaseTheme {
        constructor(
            // --== Cores principais
            public primary: string = "#ADBECE",
            public primaryLight: string = "#3B70D8",

            // --== Cores neutras
            public black00: string = "#171717",
            public white00: string = "#FFFFFF",
            public white01: string = "#DEDEDE",
            public white02: string = "#BBBBBB",

            public gray00: string = "#E9E9E9",
            public gray01: string = "#5C5C5C",
            public gray02: string = "#242424",

            public subTitle: string = "#616060",
            public footerTitle: string = "#FFFFFF99",

            public bakcgroundFooter: string = 'linear-gradient(265deg, #0E0E0E 1.11%, #1F1F1F 104.43%)',

            // --== Tamanhos de telas
            public sm: string = "640px",

            // Tela de tablet
            public md: string = "641px",

            // Tela de maior que tablet
            public lg: string = "1024px",


            public telaTablet: string = "960px",
            public telaMedia: string = "1366px",
            public telaMenor: string = "1024px",
        ) { }
}

// --== Variável de controle de tema
export const theme = new KorpayBaseTheme();



// --== Tema de margem
class KorpayMarginTheme {
    constructor(
        private size: string = theme.lg,
        public paper: string = "280px",
        public navbar: string = "148px",
    ) {
        switch (size) {
            case theme.sm:
                this.paper = "24px"
                this.navbar = "0px"
                break;
            default:
                break;
        }

    }
}

// --== Variável de controle de tema
export const margins = (size: string = theme.lg) => new KorpayMarginTheme(size);



import { KorpayStyledWrapper } from "./korpay-wrapper-styles"

export interface KorpayWrapperProps {
    children?: JSX.Element | JSX.Element[],
    justify?: 'center' | 'end' | 'start' | 'space-between';
    align?: 'center' | 'end' | 'start';
    direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
    width?: string;
    height?: string;
    color?: string;
    borderRadius?: string;
    borderBottomLeftRadius?: string;
    borderBottomRightRadius?: string;
    background?: string;
    gap?: string;
    paddingTop?: string;
    paddingBottom?: string;
    marginTop?: string;
    marginBottom?: string;
    paddingLeft?: string;
    paddingRight?: string;
    maxWidth?: string;
    maxHeight?: string;
    id?: string;
    backgroundImage?: string;
    isMobile?: boolean;
}

export const KorpayWrapper = ({ ...etc }: KorpayWrapperProps) => {
    return (
        <KorpayStyledWrapper {...etc} id={etc.id}>
            {etc.children}
        </KorpayStyledWrapper>
    )
}
import { useEffect } from "react";

export const useKorpayScroll = (config : {onNotTop ?: () => void, onTop ?: () => void}) => {

    const handleScroll = () => {
        const isNotTop = window.scrollY > 100;
        if (isNotTop && config.onNotTop) config.onNotTop() 
        else if(config.onTop) config.onTop();
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}

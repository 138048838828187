import { KorpayStyledHorizontalDivider } from "./korpay-horizontal-divider-styles"

export interface KorpayHorizontalDividerProps {
    size?: string;
    background?: string;
}

export const KorpayHorizontalDivider = ({ ...etc }: KorpayHorizontalDividerProps) => {
    return (
        <KorpayStyledHorizontalDivider {...etc} />
    )
}
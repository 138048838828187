import {
    KorpayStyledViewCardDetalhe,
    KorpayStyledViewCardDetalheImage,
    KorpayStyledViewCardDetalheTextSubTitle,
    KorpayStyledViewCardDetalheTextTitle
} from "./styles/korpay-view-card-second-styles"
import { KorpayViewCardWrapper } from "./styles/korpay-view-card-wrapper-styles"

export interface KorpayViewCardDetalheProps {
    image?: string,
    title?: string,
    titleSize?: string,
    subTitle?: string,
    subTitleSize?: string,
    direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse',
    heightImage?: string,
    paddingSides?: string,
    paddingImage?: string,
    padding?: string,
    background?: string,
    gap?: string,
    gapImage?: string,
    borderTopRightRadius?: string,
    borderTopLeftRadius?: string,
    borderBottomRightRadius?: string,
    borderBottomLeftRadius?: string,
    width?: string,
    widthImage?: string,
    minHeight?: string,
    line?: string,
    lineTitle?: string,
    justify?: string,
}

export const KorpayViewCardDetalhe = ({ ...etc }: KorpayViewCardDetalheProps) => {
    return (
        <KorpayViewCardWrapper {...etc}>

            <KorpayStyledViewCardDetalheImage {...etc} src={etc.image} />

            <KorpayStyledViewCardDetalhe {...etc}>

                <KorpayStyledViewCardDetalheTextTitle {...etc}>
                    {etc.title}
                </KorpayStyledViewCardDetalheTextTitle>

                <KorpayStyledViewCardDetalheTextSubTitle {...etc}>
                    {etc.subTitle}
                </KorpayStyledViewCardDetalheTextSubTitle>

            </KorpayStyledViewCardDetalhe>

        </KorpayViewCardWrapper>
    )
}
import { KorpaySection } from "@components/korpay-section/korpay-section"
import { KorpayStyledCoverModelSticker } from "@components/korpay-stickers/korpay-stickers-styles"
import { KorpayTypography } from "@components/korpay-typography/korpay-typography"
import { theme } from "@theme/korpay-theme"
import coverBackground from "@images/background-cover.png"
import { KorpayWrapper } from "@components/korpay-wrapper/korpay-wrapper"
import { KorpayStyledButton } from "@components/korpay-button/korpay-button-styles"
import { useKorpayAgent } from "@hooks/use-korpay-agent"
import coverBackgroundMobile from '@images/cover-model-mobile.png'


export const KorpayCapaSectionMobile = () => {
    const agent = useKorpayAgent()

    return (
        <div id="capa">
            <KorpaySection texture={agent.isMobile() ? coverBackgroundMobile : coverBackground}
                height='780px' color={theme.black00} marginBottom="50px">

                <KorpayWrapper height="100%" width="100%" align="center" justify="space-between" id={'home'}>

                    <KorpayWrapper width={agent.isMobile() ? "100%" : "50%"} gap="35px" align="start" direction="column">

                        <KorpayTypography color={theme.white00} size={agent.isMobile() ? "32px" : "56px"} weight={700} line="38px">
                            <KorpayTypography>
                                {`O melhor `}
                            </KorpayTypography>
                            <KorpayTypography>
                                {`caminho para `}
                            </KorpayTypography>
                            <KorpayTypography>
                                {`a solução financeira`}
                            </KorpayTypography>
                        </KorpayTypography>

                        <KorpayTypography weight={400} color={theme.white00} size={agent.isMobile() ? "18px" : "20px"} line="150%">
                            Conta digital, investimentos e segurança total. Conheça nossa historia e nossos serviços.
                        </KorpayTypography>

                        {/* <KorpayStyledButton width="218px" fontSize="16px" height="47px"
                            radius="50px" fontWeight={800} color={theme.primary}>
                            <KorpayTypography href="https://app-signup.korpay.me/" target="_blank" rel="noopener noreferrer"
                                color="#0B0C13">Abra sua conta</KorpayTypography>
                        </KorpayStyledButton> */}

                    </KorpayWrapper>
                    {agent.isMobile() ? <></> : <KorpayStyledCoverModelSticker />}
                </KorpayWrapper>

            </KorpaySection>
        </div>
    )
}
import { Size } from "@components/korpay-breakpoint/korpay-breakpoint";
import styled, { css } from "styled-components";
import { KorpaySectionProps } from "./korpay-section";

export const KorpayStyledSection = styled.div<KorpaySectionProps>`
    background: ${props => props.color};
    box-sizing: border-box;
    padding-bottom: ${props => props.paddingBottom ?? '0px'};
    display: flex;
    flex-direction: column;
    align-items: center;

    ${({ isMobile, height }) => isMobile && css`
        height: ${height};
    `}
    
    ${({ isMobile, views }) => (isMobile === false) && css`
            height: ${`${views! * 100}vh`};
    `}

    ${props => props.texture && css`
        background-image: ${`url(${props.texture})`};
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 2px;
    `}

    @media (min-width: 960px) and (min-height: 540px) {
        padding-left: 46px;
        padding-right: 46px;
        padding-bottom: 0;
    }

    @media (min-width: 412px) and (min-height: 870px) {
        padding-left: 46px;
        padding-right: 46px;
        padding-bottom: 0;
    }

    @media (min-width: ${Size.desktopM}px) and (max-height: 768px) {
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 42px;
    }

    @media (min-width: 1367px) and (max-width: ${Size.desktopL}px) {
        padding-left: 150px;
        padding-right: 150px;
        padding-bottom: 54px;
    }

    @media (min-width: 1920px) and (max-height: 1080px) {
        padding-left: 250px;
        padding-right: 250px;
        padding-bottom: 54px;
    }


    @media (min-width: 2560px) and (min-height: 1080px) {
        padding-left: 480px;
        padding-right: 480px;
        padding-bottom: 54px;
    }

    @media (min-width: 2880px) and (min-height: 1453px) {
        padding-left: 480px;
        padding-right: 480px;
        padding-bottom: 54px;
    }
    
    @media (width: 320px) and (height: 569px) {
        padding-left: 24px;
        padding-right: 24px;
        margin-bottom: ${props => props.marginBottom ?? '0px'};
    }

    @media (max-width: 480px) and (max-height: 854px) {
        padding-left: 24px;
        padding-right: 24px;
        margin-bottom: ${props => props.marginBottom ?? '0px'};
    }

    transition: all 1s ;
`
import { styled } from "styled-components";
import cover from "@images/cover-model.png";


export const KorpayStyledCoverModelSticker = styled.div<{isMobile?: boolean}>`
    background-image: ${`url(${cover})`};
    background-repeat: no-repeat;
    background-position: center;
    width: 649px;
    height: 597px;
`
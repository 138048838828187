import { KorpayFooterMobile } from "@components/korpay-footer/korpay-footer-mobile/korpay-footer-mobile"
import { KorpayPaper } from "@components/korpay-paper/korpay-paper"

//Pages
import { KorpayCapaSectionMobile } from "../sections/korpay-mobile/korpay-capa-section-mobile"
import { KorpayDetalhesSectionMobile } from "../sections/korpay-mobile/korpay-detalhes-section-mobile"
import { KorpaySegurancaSectionMobile } from "../sections/korpay-mobile/korpay-seguranca-section-mobile"

import { KorpayFooter } from "@components/korpay-footer/korpay-footer"
import { useKorpayAgent } from "@hooks/use-korpay-agent"
import { KorpayBaixeAppSection } from "../sections/korpay-baixe-app-section"
import { KorpayCapaSection } from "../sections/korpay-capa-section"
import { KorpayDetalhesSection } from "../sections/korpay-detalhes-section"
import { KorpaySaberMaisSection } from "../sections/korpay-saber-mais-section"
import { KorpaySegurancaSection } from "../sections/korpay-seguranca-section"

export const HomePage = () => {
    
    // @ts-ignore
    const mobile = useKorpayAgent();

    return (
        <KorpayPaper>
            <>
                {mobile.isMobile() ? (
                    <>
                        <KorpayCapaSectionMobile />

                        <KorpaySegurancaSectionMobile />

                        <KorpayDetalhesSectionMobile />

                        <KorpayFooterMobile />
                    </>
                ) : (
                    <>
                        <KorpayCapaSection />

                        <KorpaySegurancaSection />

                        <KorpayDetalhesSection />

                        <KorpaySaberMaisSection />

                        <KorpayBaixeAppSection />

                        <KorpayFooter />
                    </>
                )}
            </>

        </KorpayPaper>
    )
}
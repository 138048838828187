import styled from "styled-components";
import { KorpayViewCardProps } from "../korpay-view-card";
import { theme } from "@theme/korpay-theme";
import { Size, device } from "@components/korpay-breakpoint/korpay-breakpoint";

export const KorpayStyledViewCardImage = styled.img<KorpayViewCardProps>`
    width: 100%;
    height: ${props => props.height ?? '100%'};
    border-top-right-radius: ${props => props.radiusTopRight};
    border-top-left-radius: ${props => props.radiusTopLeft};

    @media ${device.desktopM} {
        
    }
`

export const KorpayStyledViewCardText = styled.div<KorpayViewCardProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    width: 100%;
    padding: 24px 0;
    gap: 24px;
    
    background-color: ${theme.gray02};
    border-bottom-right-radius: ${props => props.radius};
    border-bottom-left-radius: ${props => props.radius};
`

export const KorpayStyledViewCard = styled.div<KorpayViewCardProps>`
    display: flex;
    flex-direction: ${props => props.direction ?? 'column'};

    @media (min-width: ${Size.desktopM}px) and (min-height: 1080px) {
        justify-content: space-around;
        align-items: center;
        padding: 16px 0;
        width: 100%;
    }

    @media (min-width: ${Size.desktopM}px) and (max-height: 768px) {
        width: 100%;
        justify-content: space-around;
        align-items: center;
    }

    @media (min-width: ${Size.desktopM}px) and (max-height: 969px) {
        width: 100%;
        justify-content: space-around;
        align-items: center;
    }

    @media (min-width: ${Size.desktopM}px) and (min-height: 1040px) {
        width: 100%;
        justify-content: space-around;
        align-items: center;
    }

    @media (min-width: 320px) and (min-height: 569px) {
       padding: 0 10px;
    }
`

export const KorpayStyledViewCardTitle = styled.div<KorpayViewCardProps>`
    color: ${theme.white00};
    line-height: 150%;
    letter-spacing: -1.2px;

    @media (min-width: 320px) and (min-height: 569px) {
            font-size: 18px;
    }

    @media (min-width: ${Size.desktopM}px) and (max-height: 768px) {
        font-size: 28px;
    }

    @media (min-width: 960px) and (min-height: 540px) {
        font-size: 22px;
    }

    @media (min-width: ${Size.desktopM}px) and (max-height: 969px) {
        font-size: 28px;
    }

    @media (min-width: ${Size.desktopM}px) and (min-height: 1040px) {
        font-size: 30px;
    }

    @media (min-width: ${Size.desktopM}px) and (min-height: 1938px) {
        font-size: 45px;
    }
`

export const KorpayStyledViewCardSubTitle = styled.div<KorpayViewCardProps>`
    color: rgba(235, 236, 240, 0.70);
    font-weight: 600;
    line-height: 150%;
    letter-spacing: -0.72px;
    width: 69%;
    
    @media (min-width: ${Size.desktopM}px) and (max-height: 768px) {
        font-size: 18px;
    }

    @media (min-width: 960px) and (min-height: 540px) {
        font-size: 16px;
        width: 100%;
    }

    @media (min-width: ${Size.desktopM}px) and (max-height: 969px) {
        font-size: 18px;
        width: 86%;
    }

    @media (min-width: ${Size.desktopM}px) and (min-height: 1040px) {
        font-size: 19px;
        line-height: 140%;
        letter-spacing: 0.28px;
        width: 69%;
    }

    @media (min-width: ${Size.desktopM}px) and (min-height: 1938px) {
        font-size: 35px;
        line-height: 120%;
        letter-spacing: 2px;
    }

    @media (min-width: 320px) and (min-height: 569px) {
        width: 100%;
    }
`

export const KorpayStyledViewCardButton = styled.div<KorpayViewCardProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.primary};
    border-radius: 50px;
    font-weight: 700;
    color: ${theme.black00};

    @media (min-width: ${Size.desktopM}px) and (max-height: 768px) {
        width: 224px;
        height: 55px;
        font-size: 22px;
    }

    @media (min-width: 960px) and (min-height: 540px) {
        width: 224px;
        height: 55px;
        font-size: 22px;
    }

    @media (min-width: ${Size.desktopM}px) and (max-height: 969px) {
        width: 265px;
        height: 55px;
        font-size: 22px;
    }

    @media (min-width: ${Size.desktopM}px) and (min-height: 1040px) {
        width: 265px;
        height: 60px;
        font-size: 22px;
    }
`

export const KorpayStyledViewCardButtonWrapper = styled.div<KorpayViewCardProps>`
    display: flex;
    flex-direction: row;
    height: auto;
    width: 100%;
    gap: auto;
    max-width: 290px;
    max-height: auto;
    justify-content: flex-end;
    align-items: flex-start;
    padding-right: 42px;

    @media (min-width: ${Size.desktopM}px) and (max-height: 768px) {

    }

    @media (min-width: 960px) and (min-height: 540px) {
        justify-content: center;
        align-items: center;
        padding-right: 0px;
    }

    @media (min-width: ${Size.desktopM}px) and (max-height: 969px) {

    }

    @media (min-width: ${Size.desktopM}px) and (min-height: 1040px) {

    }
`

export const KorpayStyledAccordionWrapper = styled.div<KorpayViewCardProps>`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 24px;
    background: #F9F8F8;
    padding-bottom: 66px;
    margin-top: 2rem;
    padding-left: 142px;
    padding-right: 142px;

    @media (min-width: ${Size.desktopM}px) and (max-height: 768px) {
        padding: 70px;
    }

    @media (max-width: 960px) and (max-height: 540px) {
        margin-top: 42px;
        margin-bottom: 42px;
        padding-left: 66px;
        padding-right: 66px;
        padding-top: 4rem;
        gap: 3rem;

        max-height: 760px;
        min-height: 750px;
    }

    @media (max-width: 480px) and (max-height: 854px) {
        display: flex;
        flex-direction: column;
        margin: 0px;
        width: 100%;
        align-items: center;
        background: none;
        padding: 0px;
    }

    @media (min-width: 412px) and (min-height: 840px) {
        display: flex;
        flex-direction: column;
        margin: 0px;
        width: 100%;
        align-items: center;
        background: none;
        padding: 0px;
    }

    @media (min-width: ${Size.desktopM}px) and (min-height: 1040px) {
        padding: 32px;
        width: 1370px;
    }

    @media (min-width: 1920px) and (max-height: 969px) {
        padding: 70px;
        width: 100%;
    }

    @media (min-width: 2560px) and (min-height: 1060px) {
        padding: 70px 0;
        width: 100%;
    }
`

// Estilização da section de baixar App ==

export const KorpayStyledBaixarAppWrapper = styled.div<{ background?: string; }>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-image: ${props => `url(${props.background})`};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-top: 15px;
    padding-bottom: 15px;

    @media (max-width: 960px) and (max-height: 540px) {
        gap: 24px;
    }

    @media (min-width: ${Size.desktopM}px) and (max-height: 969px) {
        gap: 200px;
    }

    @media (min-width: ${Size.desktopM}px) and (min-height: 1040px) {
        gap: 87px;
    }
`

export const KorpayStyledBaixarAppImage = styled.img`
    @media (min-width: ${Size.desktopM}px) and (max-height: 768px) {

    }

    @media (max-width: 960px) and (max-height: 540px) {
        width: 350px;
    }

    @media (max-width: 1024px) and (max-height: 640px) {
        width: 470px;
    }

    @media (min-width: ${Size.desktopM}px) and (max-height: 969px) {
        width: 580px;
    }

    @media (min-width: ${Size.desktopM}px) and (min-height: 1040px) {
        width: 780px;
    }
`

export const KorpayStyledBaixarAppTitle = styled.div`
    font-weight: 800;
    color: #FFFFFF;

    @media (min-width: ${Size.desktopM}px) and (max-height: 768px) {

    }

    @media (min-width: 960px) and (min-height: 540px) {
        font-size: 32px;
    }

    @media (min-width: ${Size.desktopM}px) and (max-height: 969px) {
        font-size: 42px;
    }

    @media (min-width: ${Size.desktopM}px) and (min-height: 1040px) {
        font-size: 50px;
    }
`

export const KorpayStyledBaixarAppSubTitle = styled.div`
    color: #FFF;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 115.385% */
    letter-spacing: -0.78px;

    @media (min-width: ${Size.desktopM}px) and (max-height: 768px) {

    }

    @media (max-width: 1024px) and (max-height: 640px) {
        font-size: 24px;
    }

    @media (min-width: 960px) and (min-height: 540px) {
        font-size: 24px;
    }

    @media (min-width: ${Size.desktopM}px) and (max-height: 969px) {
        font-size: 24px;
    }

    @media (min-width: ${Size.desktopM}px) and (min-height: 1040px) {
        font-size: 37px;
    }
`

export const KorpayStyledBaixarAppContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    @media (min-width: ${Size.desktopM}px) and (max-height: 768px) {
        width: 770px;
    }

    @media (max-width: 1024px) and (max-height: 640px) {
        padding-right: 37px;
    }

    @media (max-width: 960px) and (max-height: 540px) {
        width: 465px;
        gap: 32px;
    }

    @media (min-width: ${Size.desktopM}px) and (max-height: 969px) {
        width: 520px;
    }

    @media (min-width: ${Size.desktopM}px) and (min-height: 1040px) {
        width: 770px;
    }
`
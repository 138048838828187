import { KorpayAccordion } from "@components/korpay-accordion/korpay-accordion"
import { KorpayStyledAccordion } from "@components/korpay-accordion/korpay-accordion-style"
import { KorpayStyledAccordionWrapper } from "@components/korpay-card/styles/korpay-view-card-styles"
import { KorpayTypography } from "@components/korpay-typography/korpay-typography"
import { KorpayWrapper } from "@components/korpay-wrapper/korpay-wrapper"
import { useState } from "react"

export const KorpaySaberMaisSectionMobile = () => {
    const [openAccordionIndex, setOpenAccordionIndex] = useState<number | null>(1);

    const handleAccordionClick = (index: number) => {
        if (index === openAccordionIndex) {
            setOpenAccordionIndex(null);
        } else {
            setOpenAccordionIndex(index);
        }
    };

    const accordionData = [
        { title: "Por que Escolher a Korpay?", content: "Na Korpay, oferecemos soluções financeiras eficientes e confiáveis para negócios locais e internacionais, com ética e integridade como princípios fundamentais. Nossa cultura é pautada em qualidade e responsabilidade financeira, proporcionando uma experiência excepcional para nossos clientes. Escolha a Korpay para confiabilidade e integridade em suas transações financeiras." },
        { title: "Qual a importância da cotação do dólar americano?", content: "O dólar dos Estados Unidos é a moeda de reserva mais importante em todo o mundo devido à sua estabilidade, aceitação generalizada e facilidade de troca. Sua confiabilidade econômica e longa história como referência fazem com que seja amplamente utilizado em cotações e transações financeiras internacionais, exercendo um impacto substancial nos mercados globais e na economia mundial." },
        { title: "O que é câmbio?", content: "Câmbio significa a troca entre duas moedas de dois países diferentes. A diferença de valores entre uma moeda e outra gera a taxa de câmbio, que representa a diferença de valores entre uma unidade monetária de cada país. Pode-se realizar o câmbio via transferência internacional ou trocar o papel moeda." },
        { title: "O que é taxa de câmbio?", content: "A taxa de câmbio é o valor relativo entre duas moedas, indicando quanto de uma é necessário para comprar uma unidade da outra. Ela é fundamental para transações internacionais e pode variar conforme a oferta e a procura no mercado de câmbio. Essas taxas afetam o custo de produtos importados/exportados, investimentos estrangeiros, turismo e a economia global." },
        { title: "Tenho segurança nos investimentos?", content: "Nossa Marca, Sua Segurança: com a Korpay, você pode ter confiança na segurança dos seus investimentos. Com uma abordagem única e moderna, estamos prontos para atender às suas necessidades financeiras com responsabilidade e eficiência." }
    ];


    return (
        <>
            <KorpayStyledAccordionWrapper>
                <KorpayTypography color="#171717" size="24px" weight={700}>Quer saber mais?</KorpayTypography>
                <KorpayTypography color="#565555" size="16px">A gente explica para você: </KorpayTypography>
            </KorpayStyledAccordionWrapper>

            <KorpayWrapper gap="48px" direction="column">
                {accordionData.map((item, index) => (
                    <KorpayStyledAccordion key={index}>
                        <KorpayAccordion fontSize="16px" fontSizeContent="14px"
                            isOpen={index === openAccordionIndex} onClick={() => handleAccordionClick(index)}
                            title={item.title} content={item.content} isLast={index === accordionData.length - 1}
                            widthIcon="24px" heightIcon="24px" />
                    </KorpayStyledAccordion>
                ))}
            </KorpayWrapper>
        </>
    )
}
import { AccordionSummaryOwnProps, Collapse } from '@mui/material';
import {
    KorpayStyledAccordion,
    KorpayStyledAccordionBorder,
    KorpayStyledAccordionButton,
    KorpayStyledAccordionContent,
    KorpayStyledAccordionIcon,
    KorpayStyledAccordionTitle
} from './korpay-accordion-style';
import { useKorpayAgent } from '@hooks/use-korpay-agent';

export interface KorpayAccordionProps {
    title?: string;
    content?: string;
    isOpen?: boolean;
    isLast?: boolean;
    onClick?: () => void;
    fontSize?: string;
    fontSizeContent?: string;
    heightIcon?: string;
    widthIcon?: string;
    paddingIcon?: string;
    isMobile?: boolean;
    colorFontContent?: string;
}

export const KorpayAccordion = ({ ...rest }: AccordionSummaryOwnProps & KorpayAccordionProps) => {
    const agent = useKorpayAgent();

    return (
      <KorpayStyledAccordion onClick={rest.onClick}>
        <KorpayStyledAccordionBorder
          isOpen={rest.isOpen}
          className={rest.isLast ? "last-accordion" : ""}
        >
          <KorpayStyledAccordionButton isMobile={agent.isMobile()}>
            <KorpayStyledAccordionTitle {...rest} isOpen={rest.isOpen}>
              {rest.title}
            </KorpayStyledAccordionTitle>
            <KorpayStyledAccordionIcon {...rest} />
          </KorpayStyledAccordionButton>

          <Collapse in={rest.isOpen} timeout={900}>
            <KorpayStyledAccordionContent
              colorFontContent={rest.colorFontContent}
              {...rest}
              dangerouslySetInnerHTML={{ __html: rest.content as any }}
            >
            </KorpayStyledAccordionContent>
          </Collapse>
        </KorpayStyledAccordionBorder>
      </KorpayStyledAccordion>
    );
}
import { theme } from "@theme/korpay-theme";
import styled, { css } from "styled-components";
import { KorpayWrapperProps } from "./korpay-wrapper";
import { Size } from "@components/korpay-breakpoint/korpay-breakpoint";

export const KorpayStyledWrapper = styled.div<KorpayWrapperProps>`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  height: ${props => props.height || 'auto'};
  width: ${props => props.width || 'auto'};
  gap: ${props => props.gap || 'auto'};
  max-width: ${props => props.maxWidth || 'auto'};
  max-height: ${props => props.maxHeight || 'auto'};
 
  background-image: ${props => `url(${props.background})`};

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  
  justify-content: ${props => {
    switch (props.justify) {
      case 'center':
        return 'center';
      case 'end':
        return 'flex-end';
      case 'start':
        return 'flex-start';
      case 'space-between':
        return 'space-between';
      default:
        return 'flex-start';
    }
  }};

  align-items: ${props => {
    switch (props.align) {
      case 'center':
        return 'center';
      case 'end':
        return 'flex-end';
      case 'start':
        return 'flex-start';
      default:
        return 'flex-start';
    }
  }};

  ${props => props.borderRadius && css`
    border-radius: ${props.borderRadius} ;
  `}

  ${props => props.color && css`
    background: ${props.color} ;
  `}

  ${props => props.paddingTop && css`
    padding-top: ${props.paddingTop} ;
  `}

  ${props => props.paddingBottom && css`
    padding-bottom: ${props.paddingBottom} ;
  `}

  ${props => props.marginTop && css`
    margin-top: ${props.marginTop} ;
  `}

  ${props => props.marginBottom && css`
    margin-bottom: ${props.marginBottom} ;
  `}

  ${props => props.paddingLeft && css`
    padding-left: ${props.paddingLeft} ;
  `}

  ${props => props.paddingRight && css`
    padding-right: ${props.paddingRight} ;
  `}

  ${props => props.borderBottomLeftRadius && css`
    border-bottom-left-radius: ${props.borderBottomLeftRadius} ;
  `}

  ${props => props.borderBottomRightRadius && css`
    border-bottom-right-radius: ${props.borderBottomRightRadius} ;
  `}

  ${({ isMobile }) => isMobile && css`
    @media (min-width: ${theme.md}) {
        width: 100%;
      }
  `}

  @media (min-width: ${Size.desktopM}px) and (max-height: 768px) {
    
    }
`;
import { KorpayNavbarItem } from "@components/korpay-navbar-item/korpay-navbar-item"
import { KorpayWrapper } from "@components/korpay-wrapper/korpay-wrapper"
import { KorpayStyledNavbar, KorpayStyledNavbarLogo, KorpayStyledNavbarWrapper } from "./korpay-navbar-styles"
import { useKorpayScroll } from "@hooks/use-korpay-scroll"
import { useState } from "react"

export const KorpayNavbar = () => {
    const [top, setTop] = useState(true)

    // --== Alterando estilo caso o usuário role a página
    useKorpayScroll({
        onNotTop: () => setTop(false),
        onTop: () => setTop(true)
    });

    return (
        <KorpayStyledNavbar isTop={top}>
            <KorpayWrapper align="center">
                <KorpayStyledNavbarLogo isTop={top} />
            </KorpayWrapper>
            <KorpayStyledNavbarWrapper>
                <KorpayNavbarItem weight={800} size="20px" href="#capa" text="Inicio" color='#BFC2C7' />
                <KorpayNavbarItem href="#sobre-nos" text="Sobre nós" />
                <KorpayNavbarItem href="#detalhes" text="Investimento" />
                <KorpayNavbarItem href="#saber-mais" text="Quer saber mais?" />
                <KorpayNavbarItem href="#nossas-taxas" text="Nossas taxas" />
                <KorpayNavbarItem href="#baixar-app" text="Conta" />
            </KorpayStyledNavbarWrapper>
        </KorpayStyledNavbar>
    )
}
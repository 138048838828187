import { KorpaySection } from "@components/korpay-section/korpay-section"
import { KorpayWrapper } from "@components/korpay-wrapper/korpay-wrapper"
import { theme } from "@theme/korpay-theme"
import { KorpayFooterInfo } from "./korpay-footer-parts/korpay-footer-info"

//importação das imagens
import { useKorpayAgent } from "@hooks/use-korpay-agent"
import instagram from '@images/korpay-instagram.svg'
import linkedin from '@images/korpay-linkedin.svg'
import logo from '@images/logo-footer-korpay.png'
import { KorpayStyledFooter, KorpayStyledFooterContainerLeft, KorpayStyledFooterContainerRight } from "./korpay-footer-styles"
import { KorpayHorizontalDivider } from "@components/korpay-divider/korpay-horizontal-divider"


export const KorpayFooter = () => {
    const agent = useKorpayAgent();

    return (
        <KorpaySection color={(agent.isMobile()) ? theme.bakcgroundFooter : theme.bakcgroundFooter}>

            <KorpayWrapper width="100%" paddingTop="5%" height="2px">
                <KorpayHorizontalDivider background="#303030" size="2px" />
            </KorpayWrapper>

            <KorpayStyledFooter>
                <KorpayStyledFooterContainerLeft>

                    <KorpayWrapper gap="5rem" width="100%">
                        <KorpayFooterInfo fontSizeTitle="18px"
                            fontSizeSubTitle="16px" title="Korpay bank" itens={KorpayBankFooterInfoDetails} />

                        <KorpayFooterInfo title="Segurança e Privacidade"
                            fontSizeTitle="18px" fontSizeSubTitle="16px" itens={SegurancaEPrivacidadeFooterInfoDetails} />
                    </KorpayWrapper>

                    <KorpayWrapper paddingTop="22px" paddingBottom="34px" direction="column">
                        <KorpayFooterInfo fontSizeTitle="18px" fontSizeSubTitle="16px" itens={KorpayBankFooterIcon} />
                    </KorpayWrapper>

                </KorpayStyledFooterContainerLeft>

                <KorpayStyledFooterContainerRight>

                    <KorpayFooterInfo title="Nosso contato"
                        fontSizeTitle="18px" fontSizeSubTitle="16px" itens={NossoContatoFooterInfoDetails} />

                    <KorpayFooterInfo title="Central de Relacionamento:"
                        fontSizeTitle="18px" fontSizeSubTitle="16px" itens={NossoCentralFooterInfoDetails} />

                    <KorpayFooterInfo title="SAC"
                        fontSizeTitle="18px" fontSizeSubTitle="16px" itens={NossoSacFooterInfoDetails} />
                </KorpayStyledFooterContainerRight>

            </KorpayStyledFooter>

        </KorpaySection>
    )
}

// --== Links e conteúdos da aba "Korpay Bank"
export const KorpayBankFooterInfoDetails = [
    {
        text: "Sobre nós"
    },
    {
        text: "Trabalhe Conosco: Seja um Kor!!"
    },
    {
        text: "Perguntas Frequentes"
    }
]

export const KorpayBankFooterIcon = [
    {
        buttons: [
            {
                icon: logo,
                redirect: '#inicio'
            }
        ]
    },
]

// --== Links e conteúdos da aba segurança e privacidade
export const SegurancaEPrivacidadeFooterInfoDetails = [
    {
        text: "Termos de Uso e Política de Privacidade"
    },
    {
        text: "Portal da Privacidade"
    },
    {
        text: "Segurança contra fraude"
    },
    {
        text: "Política de Segurança Cibernética"
    },
    {
        text: "Segurança da Informação"
    },
]

// --== Links e conteúdos da aba segurança e privacidade
export const NossoContatoFooterInfoDetails = [
    {
        buttons: [
            {
                icon: instagram,
                redirect: ''
            },
            {
                icon: linkedin,
                redirect: ''
            }
        ]
    },
]

export const NossoCentralFooterInfoDetails = [
    {
        desc: "(11) 2050- 8108 (9h às 17h de segunda a sexta, exceto fins de semana e feriados)"
    },
]

export const NossoSacFooterInfoDetails = [
    {
        desc: "0800-000-1906 (9h às 17h de segunda a sexta, exceto fins de semana e feriados)"
    },
]
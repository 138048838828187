
// --== Realizando importação de imagens
import KorViewCard01 from "@images/card-view-01.svg"
import KorViewCard02 from "@images/card-view-02.svg"

// --== Realizando importação de ícones
import KorNotificationIcon from "@icons/notification.svg"
import KorShieldIcon from "@icons/shield.svg"
import KorWirelessIcon from "@icons/wireless.svg"

import { KorpayTextCard } from "@components/korpay-card/korpay-text-card"
import { KorpayViewCard } from "@components/korpay-card/korpay-view-card"
import { KorpayHorizontalDivider } from "@components/korpay-divider/korpay-horizontal-divider"
import { KorpaySection } from "@components/korpay-section/korpay-section"
import { KorpayTypography } from "@components/korpay-typography/korpay-typography"
import { KorpayWrapper } from "@components/korpay-wrapper/korpay-wrapper"
import { useKorpayAgent } from "@hooks/use-korpay-agent"
import { theme } from "@theme/korpay-theme"

export const KorpaySegurancaSection = () => {
    const agent = useKorpayAgent();

    return (
        <div id="sobre-nos">

            <KorpaySection color={theme.black00} texture={theme.black00}>

                <KorpayWrapper justify="start" borderRadius="24px"
                    direction="column" paddingLeft="10px" paddingRight="10px" gap="16px" paddingTop="3%">

                    <KorpayWrapper width="100%" direction="column" gap="24px" align="center" justify="center" paddingBottom="10px">
                        <KorpayTypography color={theme.white00} size={'45px'} weight={700}>Sobre nós</KorpayTypography>
                    </KorpayWrapper>

                    <KorpayWrapper direction="row" align="start" justify="start" gap="48px">
                        <KorpayTextCard align="center" textAlign="start" justify="start"
                            icon={KorWirelessIcon} text="Surgimento" titleSize="20px" isMobile={agent.isMobile()} sizeIcon="27px"
                            content="A Korpay surgiu em 2021 com a missão de revolucionar o mercado financeiro, oferecendo soluções práticas." />

                        <KorpayTextCard align="center" textAlign="start" justify="start" icon={KorNotificationIcon}
                            text="Exclusividade" titleSize="20px" isMobile={agent.isMobile()}
                            titleContent='16px' sizeIcon="27px" content="Chega de burocracia dos bancos convencionais, oferecemos tudo 
                            que você precisa para uma gestão financeira de sucesso."/>

                        <KorpayTextCard align="center" textAlign="start" justify="start" icon={KorShieldIcon}
                            text="Transparência" titleSize="20px"
                            isMobile={agent.isMobile()} sizeIcon="27px" content="Tempo, comodidade, foco no cliente e profissionais 
                            capacitados para tornar sua experiência ainda melhor."/>
                    </KorpayWrapper>

                </KorpayWrapper>

                <KorpayWrapper width="100%" paddingTop="40px" paddingBottom="40px">
                    <KorpayHorizontalDivider size="3px" background="#383838" />
                </KorpayWrapper>

                <KorpayWrapper marginBottom="4rem" justify="center" width="100%">
                    <KorpayViewCard isMobile={false} radius="30px" radiusTopLeft="38px" padding="8px"
                        image={KorViewCard01} justify="space-between" title="Korpay: Facilitando o Câmbio Internacional"
                        titleContent="Facilitamos o câmbio de moedas estrangeiras para torná-lo acessível, 
                        sem estresse, oferecendo uma ampla gama de opções e taxas altamente competitivas." width="100%" fontSizeContent='18px' lineHeight="24px" />
                </KorpayWrapper>

                <KorpayWrapper marginBottom="40px" justify="center" width="100%">
                    <KorpayViewCard isMobile={false} radius="30px" fontSize="32px" padding="8px"
                        image={KorViewCard02}  justify="space-between" radiusTopLeft="38px"
                        title="Simplificando o Câmbio com Korpay" width="100%"
                        titleContent="Korpay torna o câmbio de moedas estrangeiras simples, 
                        acessível e sem estresse, com uma variedade de opções de câmbio e taxas competitivas." fontSizeContent='18px' lineHeight="24px" />
                </KorpayWrapper>

            </KorpaySection>
        </div>
    )
}
import { theme } from "@theme/korpay-theme";
import styled from "styled-components";
import { KorpayViewCardDetalheProps } from "../korpay-view-card-second";

export const KorpayStyledViewCardDetalhe = styled.div<KorpayViewCardDetalheProps>`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
    
    @media (max-width: 960px) and (max-height: 540px) {
        padding-left: 40px;
        padding-right: 44px;
        width: 442px;
    }
    
    @media (min-width: 320px) and (min-height: 569px) {
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 16px;
    }


    @media (min-width: 472px) and (min-height: 840px) {
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 16px;
        padding-top: 17px;
    }
    
    @media (min-width: 1920px) and (min-height: 1080px) {
        width: 609px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        gap: 24px;
    }

    @media (min-width: 1366px) and (min-height: 768px) {
        width: 600px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        gap: 13px;
    }
    
`

export const KorpayStyledViewCardDetalheTextTitle = styled.div<KorpayViewCardDetalheProps>`
    font-size: ${(props) => props.titleSize ?? '48px'};
    font-weight: 700;
    color: ${theme.black00};
    line-height: ${props => props.lineTitle};

    @media (min-width: 960px) and (min-height: 540px) {
        font-size: 24px;
        font-weight: 800;
        line-height: 28px;
    }

    @media (min-width: 1920px) and (min-height: 1080px) {
        color: #121212;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 50px; /* 125% */
    }

    @media (min-width: 1366px) and (min-height: 768px) {
        color: #121212;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px; /* 125% */
    }
`

export const KorpayStyledViewCardDetalheTextSubTitle = styled.div<KorpayViewCardDetalheProps>`
    font-size: ${(props) => props.subTitleSize ?? '24px'};
    font-weight: 400;
    color: ${theme.subTitle};
    line-height: ${props => props.line};

    @media (min-width: 960px) and (min-height: 540px) {
        font-size: 18px;
        font-weight: 800;
        line-height: 23px;
    }

    @media (min-width: 1920px) and (min-height: 1080px) {
        font-size: 18px;
        font-weight: 800;
        line-height: 23px;
    }

    @media (min-width: 1920px) and (min-height: 1080px) {
        color: #5C5C5C;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 116.667% */
    }

    @media (min-width: 1366px) and (min-height: 768px)  {
        color: #5C5C5C;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 116.667% */
    }
`

export const KorpayStyledViewCardDetalheImage = styled.img<KorpayViewCardDetalheProps>`
    height: ${(props) => props.heightImage ?? '370px'};
    width: ${props => props.widthImage ?? 'none'};

    @media (min-width: 960px) and (min-height: 540px) {
        height: 261px;
        width: 335px;
    }

    @media (min-width: 1920px) and (min-height: 1080px) {
        height: 419px;
        width: 500px;
    }

    @media (min-width: 1366px) and (min-height: 768px)  {
        height: 419px;
        width: 500px;
    }
`
import styled, { css } from "styled-components";
import { KorpayAccordionProps } from "./korpay-accordion";
import OpenIcon from "@icons/arrow-down.svg";

export const KorpayStyledAccordion = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: transparent;

  .tax-table {
    margin-top: 25px;

    tr {
      td {
        padding-inline: 10px;
        padding-block: 3px;
        font-size: 18px;
      }

      td:nth-child(2) {
        font-size: 12px;

        span {
          font-size: 18px;
        }
      }

      &:nth-child(odd) {
        background-color: #ccc;
        td {
        }
      }
    }
  }
`;

export const KorpayStyledAccordionBorder = styled.div<KorpayAccordionProps>`
  border-bottom: 2px solid #ebecf0;
  transition: 0.5s;

  &.last-accordion {
    border-bottom: none;
  }
`;

export const KorpayStyledAccordionButton = styled.button<KorpayAccordionProps>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: transparent;
  padding-bottom: 12px;
  border: none;
  transition: 0.5s;

  ${(props) =>
    props.isMobile === false &&
    css`
      cursor: pointer;
    `}
`;

export const KorpayStyledAccordionTitle = styled.div<KorpayAccordionProps>`
  font-size: ${(props) => props.fontSize ?? "24px"};
  font-weight: 700;
  text-align: left;
  color: ${(props) => (props.isOpen ? "#ADBECE" : "#171717")};
  transition: 0.5s;
`;

export const KorpayStyledAccordionContent = styled.div<KorpayAccordionProps>`
  text-align: justify;
  color: ${(props) => props.colorFontContent};
  font-size: ${(props) => props.fontSizeContent ?? "22px"};
  padding: 10px 0;
  padding-left: 8px;
  transition: 0.5s;
  font-weight: 500;
  line-height: 150%;
  padding-right: 12px;
`;

export const KorpayStyledAccordionIcon = styled.div<KorpayAccordionProps>`
  transform: ${({ isOpen }) => (isOpen ? "rotate(-180deg)" : "rotate(0)")};
  background-image: ${`url(${OpenIcon})`};

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #adbece;
  transition: 0.7s;

  height: ${(props) => props.heightIcon ?? "34px"};
  width: ${(props) => props.widthIcon ?? "41px"};
  padding: ${(props) => props.paddingIcon ?? "0"};
`;

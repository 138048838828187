import { Size } from "@components/korpay-breakpoint/korpay-breakpoint";
import styled from "styled-components";

export const KorpayStyledFooter = styled.div<{ background?: string; }>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding-top: 55px;
    padding-bottom: 15px;

    @media (min-width: ${Size.desktopM}px) and (max-height: 768px) {
      
    }

    @media (max-width: 1024px) and (max-height: 640px) {
    }

    @media (max-width: 960px) and (max-height: 540px) {
        justify-content: space-between;
        padding-top: 0px;
    }

    @media (min-width: ${Size.desktopM}px) and (max-height: 969px) {

    }

    @media (min-width: ${Size.desktopM}px) and (min-height: 1040px) {

    }

`

export const KorpayStyledFooterContainerLeft = styled.div<{ background?: string; }>`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: ${Size.desktopM}px) and (max-height: 768px) {
      
    }

    @media (max-width: 1024px) and (max-height: 640px) {
    }

    @media (max-width: 960px) and (max-height: 540px) {

    }

    @media (min-width: ${Size.desktopM}px) and (max-height: 969px) {

    }

    @media (min-width: ${Size.desktopM}px) and (min-height: 1040px) {

    }

`

export const KorpayStyledFooterContainerRight = styled.div<{ background?: string; }>`
    display: flex;
    flex-direction: column;
    width: 20%;
    gap: 16px;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 30px;
    padding-bottom: 30px;

    @media (min-width: ${Size.desktopM}px) and (max-height: 768px) {
      
    }

    @media (max-width: 1024px) and (max-height: 640px) {
    }

    @media (max-width: 960px) and (max-height: 540px) {

    }

    @media (min-width: ${Size.desktopM}px) and (max-height: 969px) {

    }

    @media (min-width: ${Size.desktopM}px) and (min-height: 1040px) {

    }

`
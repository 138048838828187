import { ListItemButton } from "@mui/material";
import styled from "styled-components";

export interface KorpayStyledButtonProps {
    color?: string,
    width?: string;
    height?: string;
    radius?: string;
    fontSize ?: string;
    fontColor ?: string;
    fontWeight ?: number;
    border?: string;
}

export const KorpayStyledButton = styled(ListItemButton)<KorpayStyledButtonProps>`
    &&& {
        background-color: ${props => props.color};
        border-radius: ${props => props.radius};
        border: ${props => props.border};
        height: ${props => props.height || 'auto'};
        width: ${props => props.width || 'auto'};
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: ${props => props.fontWeight};
        font-size: ${props => props.fontSize};
        color: ${props => props.fontColor};
    }
`
import { KorpayTypography } from "@components/korpay-typography/korpay-typography"
import { KorpayWrapper } from "@components/korpay-wrapper/korpay-wrapper"
import imageBaixar from '@images/background-baixar-mobile.svg'
import image from '@images/baixe-app.png'

export const KorpayBaixeAppSectionMobile = () => {
    return (
        <KorpayWrapper direction="column" justify="center" align="center" height="100%"
            paddingTop="24px" borderBottomLeftRadius="20px" isMobile
            borderBottomRightRadius="20px" background={imageBaixar}>

            <KorpayWrapper direction="column" justify="center" align="center" gap="16px">

                <KorpayWrapper direction="column" justify="center" align="center" width="80%" gap="16px">
                    <KorpayTypography color="#FFFFFF" size="24px" weight={800}>Baixe o App e
                        venha conhecer o futuro</KorpayTypography>
                    <KorpayTypography color="#FFFFFF" size="15px">No App você tem total controle da
                        sua conta e segurança máxima.</KorpayTypography>
                </KorpayWrapper>

                <img src={image} alt="" style={{ height: '100%', maxHeight: '177px' }} />
            </KorpayWrapper>
        </KorpayWrapper>
    )
}
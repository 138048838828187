import { KorpayIcon } from "@components/korpay-icon/korpay-icon"
import { KorpayTypography } from "@components/korpay-typography/korpay-typography"
import { KorpayWrapper } from "@components/korpay-wrapper/korpay-wrapper"
import { theme } from "@theme/korpay-theme"

export interface KorpayTextCardProps {
    icon?: string;
    content?: string;
    text?: string;
    titleSize?: string;
    titleContent?: string;
    isMobile?: boolean;
    sizeIcon?: string;
    width?: string;
    justify?: 'center' | 'end' | 'start' | 'space-between';
    align?: 'center' | 'end' | 'start';
    textAlign?: 'center' | 'end' | 'start' | 'justify';

}

export const KorpayTextCard = ({ ...etc }: KorpayTextCardProps) => {
    return (
        <>
            {etc.isMobile ? (
                <>
                    <KorpayWrapper paddingTop="10px" direction="column" gap="16px" width={etc.width} >

                        <KorpayWrapper align="center" justify="center" gap="8px" width="100%" >
                            <KorpayIcon width={etc.sizeIcon} height={etc.sizeIcon} icon={etc.icon} />
                            <KorpayTypography size={etc.titleSize} color={theme.white00}>
                                {etc.text}
                            </KorpayTypography>
                        </KorpayWrapper>

                        <KorpayTypography textAlign="center" line="140%" size={etc.isMobile ? etc.titleContent : "18px"} weight={300} color={theme.white01}>
                            {etc.content}
                        </KorpayTypography>

                    </KorpayWrapper>
                </>
            ) : (
                <>
                    <KorpayWrapper paddingTop="10px" direction="column" gap="16px" width={etc.width} >

                        <KorpayWrapper direction="column" align={etc.align} justify={etc.justify} gap="8px" width="100%">

                            <KorpayWrapper align={etc.align} justify={etc.justify} gap="8px" width="100%">
                                <KorpayIcon width={etc.isMobile ? etc.sizeIcon : "45px"} height={etc.isMobile ? etc.sizeIcon : "45px"} icon={etc.icon} />
                                <KorpayTypography textAlign={etc.textAlign} size={"30px"} color={theme.white00}>
                                    {etc.text}
                                </KorpayTypography>
                            </KorpayWrapper>

                            <KorpayWrapper align={etc.align} justify={etc.justify} gap="8px" width="80%" paddingLeft="1rem">
                                <KorpayTypography textAlign={etc.textAlign} line="140%" size={"18px"} weight={300} color={theme.white01}>
                                    {etc.content}
                                </KorpayTypography>
                            </KorpayWrapper>

                        </KorpayWrapper>

                    </KorpayWrapper>
                </>
            )}
        </>
    )
}
import { useKorpayAgent } from "@hooks/use-korpay-agent"
import { KorpayStyledSection } from "./korpay-section-styles"

export interface KorpaySectionProps {
    name ?: string,
    children ?: JSX.Element | JSX.Element[],
    color ?: string,
    views ?: number,
    texture?: string,
    id?: string,
    isMobile?: boolean,
    height?: string,
    paddingBottom?: string
    marginBottom?: string
}

export const KorpaySection = ({...etc} : KorpaySectionProps) => {
    const mobile = useKorpayAgent()
    
    return (
        <KorpayStyledSection {...etc} isMobile={mobile.isMobile()}>
            {etc.children}
        </KorpayStyledSection>
    )
}
import { PropsWithChildren  } from "react"
import { KorpayStyledTypography } from "./korpay-typography-styles"

export interface KorpayTypographyProps extends PropsWithChildren {
  color?: string;
  size?: string;
  weight?: number;
  line?: string;
  textAlign?: "center" | "end" | "start" | "justify";
  padding?: string;
  href?: string;
  target?: string;
  rel?: string;
}

export const KorpayTypography = ({ ...etc }: KorpayTypographyProps) => {
    return (
        <KorpayStyledTypography {...etc}>
            {etc.children}
        </KorpayStyledTypography>
    )
}
import { KorpayStyledButton } from "@components/korpay-button/korpay-button-styles";
import { KorpayWrapper } from "@components/korpay-wrapper/korpay-wrapper";
import { theme } from "@theme/korpay-theme";
import { KorpayStyledViewCard, KorpayStyledViewCardButton, KorpayStyledViewCardButtonWrapper, KorpayStyledViewCardImage, KorpayStyledViewCardSubTitle, KorpayStyledViewCardText, KorpayStyledViewCardTitle } from "./styles/korpay-view-card-styles";

export interface KorpayViewCardProps {
    image?: string;
    title?: string;
    textButton?: string;
    button?: boolean;
    height?: string;
    width?: string;
    radius?: string;
    borderRadius?: string;
    fontSize?: string;
    fontSizeContent?: string;
    padding?: string;
    isMobile?: boolean;
    justify?: 'center' | 'end' | 'start' | 'space-between';
    titleContent?: string;
    lineHeight?: string;
    direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
    radiusTopRight?: string;
    radiusTopLeft?: string;
}

export const KorpayViewCard = ({ ...etc }: KorpayViewCardProps) => {
    return (

        <>
            {etc.isMobile ? (
                <>
                    <KorpayWrapper width="100%" direction="column">

                        <KorpayStyledViewCardImage src={etc.image} alt="" />
                        <KorpayStyledViewCardText {...etc}>
                            <KorpayStyledViewCard>

                            <KorpayWrapper direction="column" gap="10px">
                                    <KorpayWrapper>
                                        <KorpayStyledViewCardTitle>{etc.title}</KorpayStyledViewCardTitle>
                                    </KorpayWrapper>

                                    <KorpayWrapper paddingBottom="10px">
                                        <KorpayStyledViewCardSubTitle>{etc.titleContent}</KorpayStyledViewCardSubTitle>
                                    </KorpayWrapper>
                                </KorpayWrapper>

                                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px' }}>
                                    <KorpayWrapper paddingRight="10px" maxWidth="200px" width="100%" justify="end">
                                        <KorpayStyledButton fontSize="22px" height="37px" width="127px"
                                            radius="50px" fontWeight={700} color={theme.white00} fontColor={theme.black00}>
                                            Saiba Mais
                                        </KorpayStyledButton>
                                    </KorpayWrapper>
                                </div>

                            </KorpayStyledViewCard>
                        </KorpayStyledViewCardText>

                    </KorpayWrapper>
                </>
            ) : (
                <>
                    <KorpayWrapper {...etc} direction="column">
                        <KorpayStyledViewCardImage src={etc.image} alt="" />
                        <KorpayStyledViewCardText {...etc}>
                            <KorpayStyledViewCard direction="row">

                                <KorpayWrapper direction="column" gap="10px" paddingLeft="42px" width="74%">
                                    <KorpayWrapper paddingTop="15px">
                                        <KorpayStyledViewCardTitle>{etc.title}</KorpayStyledViewCardTitle>
                                    </KorpayWrapper>

                                    <KorpayWrapper paddingBottom="10px">
                                        <KorpayStyledViewCardSubTitle>{etc.titleContent}</KorpayStyledViewCardSubTitle>
                                    </KorpayWrapper>
                                </KorpayWrapper>

                                <KorpayStyledViewCardButtonWrapper>

                                    <KorpayStyledViewCardButton>
                                        Saiba Mais
                                    </KorpayStyledViewCardButton>

                                </KorpayStyledViewCardButtonWrapper>

                            </KorpayStyledViewCard>
                        </KorpayStyledViewCardText>
                    </KorpayWrapper>
                </>
            )}
        </>
    )
}
import { useCallback } from "react"
export const mobileWidthThreshold = 640

export const useKorpayAgent = () => {
    const isMobile = useCallback(() : boolean => {
        return (window.innerWidth < mobileWidthThreshold) 
            || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
            .test(navigator.userAgent);
    }, [])

    return {
        isMobile
    }
}
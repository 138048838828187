import styled from "styled-components";
import { KorpayIconProps } from "./korpay-icon";

export const KorpayStyledIcon = styled.div<KorpayIconProps>`
    background-image: ${props => `url(${props.icon})`};
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: ${props => props.height || 'auto'};
    width: ${props => props.width || 'auto'};
`
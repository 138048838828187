import { KorpayStyledButton } from "@components/korpay-button/korpay-button-styles"
import { KorpaySection } from "@components/korpay-section/korpay-section"
import { KorpayTypography } from "@components/korpay-typography/korpay-typography"
import { KorpayWrapper } from "@components/korpay-wrapper/korpay-wrapper"
import coverBackground from "@images/background-cover.svg"
import { theme } from "@theme/korpay-theme"

export const KorpayCapaSection = () => {

    return (
        <div id="capa">

            <KorpaySection texture={coverBackground} views={1} color={theme.black00} height="889px">

                <KorpayWrapper height="100%" width="100%" align="center" justify="space-between" id={'capa'} >

                    <KorpayWrapper gap="35px" align="start" direction="column">

                        <KorpayTypography color={theme.white00} size={'55px'} weight={700}>
                            <KorpayTypography>
                                O melhor caminho para a solução financeira
                            </KorpayTypography>
                        </KorpayTypography>

                        <KorpayTypography weight={400} color={theme.white02} size={"24px"} line="24px">
                            Conta digital, investimentos e segurança total. Conheça nossa historia e nossos serviços.
                        </KorpayTypography>

                        {/* <KorpayStyledButton width="200px" fontSize="22px" height="55px"
                            radius="50px" fontWeight={700} color={theme.white00}>
                            <KorpayTypography href="https://app-signup.korpay.me/" target="_blank" rel="noopener noreferrer"
                                color="#0B0C13">Abra sua conta</KorpayTypography>
                        </KorpayStyledButton> */}

                    </KorpayWrapper>
                </KorpayWrapper>

            </KorpaySection>
        </div>
    )
}
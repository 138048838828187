import { useEffect, useState } from 'react';

export const Size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktopS: 1024,
  desktopM: 1366,
  desktopL: 2560
}

export const device = {
  mobileS: `(min-width: ${Size.mobileS}) and (max-width: ${Size.mobileM})`,
  mobileM: `(min-width: ${Size.mobileM})`,
  mobileL: `(min-width: ${Size.mobileL})`,
  tablet: `(min-width: ${Size.tablet})`,
  laptop: `(min-width: ${Size.laptop})`,
  laptopL: `(min-width: ${Size.laptopL})`,
  desktopS: `(min-width: ${Size.desktopS}) and (max-width: 1365px)`,
  desktopM: `(min-width: ${Size.desktopM}) and (max-width: 2559px)`,
  desktopL: `(min-width: ${Size.desktopL})`
};

export const KorpayBreakpoints = (size: keyof typeof Size) => (styles: string) => `
    @media (min-width: ${Size[size]}px) {
      ${styles};
    }
  `;

export const KorpayTamanhoDaTela = () => {
  const [tamanhoTela, setTamanhoTela] = useState({
    largura: window.innerWidth,
    altura: window.innerHeight,
  });
  const [views, setViews] = useState<number>(1.7);

  useEffect(() => {
    const handleResize = () => {
      setTamanhoTela({
        largura: window.innerWidth,
        altura: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (tamanhoTela.largura <= 1366) {
      setViews(2.1);
    } else if (tamanhoTela.largura >= 1920) {
      setViews(1.65);
    }
  }, [tamanhoTela, views]);

  // Return an object with the values
  return { views, tamanhoTela };
};

import { KorpayNavbar } from "@components/korpay-navbar/korpay-navbar"
import { KorpayStyledPaper } from "./korpay-paper-styles"
import { KorpayNavbarMobile } from "@components/korpay-navbar"
import { useKorpayAgent } from "@hooks/use-korpay-agent"

export interface KorpayPaperProps {
    children?: JSX.Element | JSX.Element[]
}

export const KorpayPaper = ({ ...etc }: KorpayPaperProps) => {
    
    // @ts-ignore
    const agent = useKorpayAgent();

    return (
        <KorpayStyledPaper>
            {agent.isMobile() ? <KorpayNavbarMobile /> : <KorpayNavbar />}
            {etc.children}
        </KorpayStyledPaper>
    )
}
import { useEffect } from "react";
import { KorpayStyledA, KorpayStyledNavbarItem, KorpayStyledNavbarItemTitle } from "./korpay-navbar-item-styles";

export interface KorpayNavbarItemProps {
    text?: string;
    href?: string;
    isTop?: boolean;
    isSection?: boolean;
    color?: string;
    size?: string;
    weight?: number;
    offsetTop?: number;
}

export const KorpayNavbarItem = ({ ...etc }: KorpayNavbarItemProps) => {

    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        const targetElement = document.getElementById(etc?.href?.replace('#', '') ?? 'home'); // Substitua 'seuTituloId' pelo ID real do elemento alvo
 
        if (targetElement) {
            const targetElementPosition = targetElement.getBoundingClientRect().top + scrollPosition;
            
            // Verifica se a posição de rolagem ultrapassou o elemento alvo com o offset
            const isFixed = scrollPosition > targetElementPosition - (etc.offsetTop ?? 0);
            
            // Faça o que precisar com a informação se o elemento está fixo ou não
            if (targetElement?.id === 'nossas-taxas' && window.location.hash !== '#nossas-taxas') {
                targetElement?.querySelector('button')?.click()
            }
        }

    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <KorpayStyledA href={etc.href}>
            <KorpayStyledNavbarItem {...etc}>
                <KorpayStyledNavbarItemTitle {...etc}>
                    {etc.text}
                </KorpayStyledNavbarItemTitle>
            </KorpayStyledNavbarItem>
        </KorpayStyledA>
    )
}
import { KorpayStyledIcon } from "./korpay-icon-styles"

export interface KorpayIconProps {
    icon ?: string,
    width?: string;
    height?: string;
}


export const KorpayIcon = ({...etc} : KorpayIconProps) => {
    return (
        <KorpayStyledIcon {...etc} />
    )
}
import { Size } from "@components/korpay-breakpoint/korpay-breakpoint";
import styled from "styled-components";
import { KorpayViewCardDetalheProps } from "../korpay-view-card-second";


export const KorpayViewCardWrapper = styled.div<KorpayViewCardDetalheProps>`
    &&& {
        width: 100%;
        display: flex;
        gap: ${props => props.gapImage ?? '10%'};
        align-items: center;
        flex-direction: ${(props) => props.direction};
        margin-bottom: ${(props) => props.gap ?? ''};
        background: ${(props) => props.background ?? '#EBECF0'};
        border-top-right-radius: ${(props) => props.borderTopRightRadius ?? '9rem'};
        border-top-left-radius: ${(props) => props.borderTopLeftRadius ?? '24px'};
        border-bottom-right-radius: ${(props) => props.borderBottomRightRadius ?? '24px'};
        border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius ?? '24px'};

        @media (min-width: ${Size.desktopM}px) and (max-height: 768px) {
            gap: 34px;
        }

        @media (max-width: 1024px) and (max-height: 640px) {
            gap: 34px;
        }

        @media (min-width: ${Size.desktopM}px) and (min-height: 969px) {
            gap: 130px;
        }

        @media (min-width: ${Size.desktopM}px) and (min-height: 1040px) {
            gap: 10rem;
        }

        @media (max-width: 320px) and (max-height: 569px) {
           gap: 16px;
        }

        @media (max-width: 480px) and (max-height: 854px) {
           gap: 16px;
        }
    }
`
import { Size } from "@components/korpay-breakpoint/korpay-breakpoint";
import styled from "styled-components";

export const KorpayStyledNavbarItem = styled.div`
    cursor: pointer;
`

export const KorpayStyledA = styled.a<{ color?: string }>`
    text-decoration: none;
    color: ${props => props.color};
`

export const KorpayStyledNavbarItemTitle = styled.div<{ weight?: number }>`
    font-weight: ${({ weight }) => weight ?? 400};
    color: #BFC2C7;
    text-decoration: none;

    @media (min-width: 960px) and (min-height: 540px) {
        color: #BFC2C7;
        font-size: 16px;
    }

    @media (min-width: ${Size.desktopM}px) and (min-height: 1040px) {
        color: #BFC2C7;
        font-size: 18px;
    }

    @media (min-width: 2560px) and (min-height: 1080px) {
        color: #BFC2C7;
        font-size: 22px;
    }

    @media (min-width: ${Size.desktopM}px) and (min-height: 1938px) {
        color: #BFC2C7;
        font-size: 20px;
    }
`
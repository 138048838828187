import styled from "styled-components";
import { KorpayTypographyProps } from "./korpay-typography";

export const KorpayStyledTypography = styled.a<KorpayTypographyProps>`
    font-weight: ${props => `${props.weight}`};
    color: ${props => `${props.color}`};
    font-size: ${props => `${props.size}`};
    text-align: ${props => `${props.textAlign}`};
    line-height: ${props => `${props.line}`};
    padding-left: ${props => `${props.padding}`};
    padding-right: ${props => `${props.padding}`};
    text-decoration: none;
`
import { KorpayTypography } from "@components/korpay-typography/korpay-typography"
import { KorpayWrapper } from "@components/korpay-wrapper/korpay-wrapper"
import { theme } from "@theme/korpay-theme"

export interface KorpayFooterQuemSomosButtonProps {
    icon?: JSX.Element | any,
    redirect?: string
}


export interface KorpayFooterQuemSomosProps {
    itens?: {
        desc?: string;
        buttons?: KorpayFooterQuemSomosButtonProps[]
    }[];
    fontSizeTitle?: string;
}

export const KorpayFooterQuemSomos = ({ ...etc }: KorpayFooterQuemSomosProps) => {

    return (
        <KorpayWrapper direction="column" align="center" paddingTop="32px">
            <>
                {etc.itens?.map(item => {
                    return (
                        <KorpayWrapper direction="column" align="center">
                            <>
                                {item.buttons?.map(x => {
                                    return (
                                        <>
                                            <a href={x.redirect}>
                                                <img src={x.icon} alt="Logo Korpay bank" />
                                            </a>
                                        </>
                                    )
                                })}
                            </>
                            <>
                                {item.desc && (
                                    <KorpayTypography weight={300} color={theme.footerTitle}
                                        size={etc.fontSizeTitle} line="20.8px">
                                        {item.desc}
                                    </KorpayTypography>
                                )}
                            </>
                        </KorpayWrapper>
                    )
                })}
            </>
        </KorpayWrapper>
    )

}